import ContainerBody from "components/elements/containerElement/containerBody";
import ContainerHeader from "components/elements/containerElement/containerHeader";
import SDGCard from "components/sdgCard";
import React from "react";
import tailwindConfig from "tailwind.config";
import MetricList from "./MetricSectionElement";
import Container from "./container";
import NigerianMapWithPopper from "components/svgMaps/nigerianMapWithPopper";

const ImpactMetricSection = ({ data }) => {
  return (
    <Container>
      <ContainerHeader title={data?.title} description={data?.description} />
      <ContainerBody>
        <div className="flex flex-col ">
          <MetricList metric={data?.metrics} />
          <div className="flex flex-col items-start">
            <h4 className="pt-12 text-3xl leading-6 font-demibold">
              SDG Targets
            </h4>
            <div className="flex flex-row flex-wrap items-center justify-start mt-6">
              {data?.sdgs &&
                data?.sdgs?.map((iconName, sdgIndex) => (
                  <SDGCard
                    name={iconName.sdg}
                    iconWidth={55}
                    iconHeight={55}
                    color={tailwindConfig.theme.extend.colors.white}
                    // imageIcon={sdg.imageIcon}
                    className="p-2 mb-4 mr-2 text-white fill-white sm:mb-6 sm:mr-2 bg-primary"
                    key={`sdg-${sdgIndex}`}
                  />
                ))}
            </div>
          </div>
        </div>
        {/* right side */}
        <div className="self-start justify-self-center hidden mx-auto md:block w-full">
          {data.showMap && (
            <div className="relative flex flex-col items-end ">
              <div className="w-[700px]">
                <NigerianMapWithPopper />
              </div>
            </div>
          )}
        </div>
        {/* mobile version map wip */}
        <div className="hidden my-8 w-full">
          <NigerianMapWithPopper />
        </div>
      </ContainerBody>
    </Container>
  );
};

export default ImpactMetricSection;
